import { Provider, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Cookies from 'cookies-js'
import {
  LISTEN_PORT_COOKIE_NAME,
  WWW_TARGET,
  ROLLBAR_ACCESS_TOKEN,
  COMMIT_HASH,
  API_TARGET,
  REDIRECT_KEY,
} from '../config'
import store from '../store'
import {
  log,
  parseUrlParams,
  createSocket,
  toast,
  isValidProfile,
  setCurrentContext,
  fetchProfile,
} from '../util'
import fetchAllDocs, { fetchDomains } from '../util/fetch'
import HeaderToolbar from '../util/HeaderToolbar'

import '../styles/reset.css'
import '../styles/index.css'
import 'highlight.js/styles/vs2015.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import '../styles/util/Page.css'

import '../styles/globals.css'
import '../styles/account.css'
import '../styles/admin.css'
import '../styles/blog.css'
import '../styles/changelog.css'
import '../styles/config.css'
import '../styles/dashboard.css'
import '../styles/definitions.css'
import '../styles/home.css'
import '../styles/kubeCon.css'
import '../styles/privacy.css'
import '../styles/support.css'
import '../styles/templateEditor.css'
import '../styles/terms.css'
import '../styles/unmarkDeletion.css'
import '../styles/unsubscribe.css'

import '../styles/component/Button.css'
import '../styles/component/Dialog.css'
import '../styles/component/EnvVarEditor.css'
import '../styles/component/IngressEditor.css'
import '../styles/component/Input.css'
import '../styles/component/List.css'
import '../styles/component/NetworkManager.css'
import '../styles/component/Pill.css'
import '../styles/component/Pricing.css'
import '../styles/component/PublicTemplateList.css'
import '../styles/component/Search.css'
import '../styles/component/Select.css'
import '../styles/component/ServiceEditor.css'
import '../styles/component/Template.css'
import '../styles/component/Toast.css'
import '../styles/component/Tooltip.css'
import '../styles/component/UsageIndicator.css'
import '../styles/component/FileManager.css'

import '../styles/component/home/Features.css'
import '../styles/component/home/Footer.css'
import '../styles/component/home/Header.css'
import '../styles/component/home/Hero.css'
import '../styles/component/home/HomeHosting.css'
import '../styles/component/home/KubeSailPlatform.css'
import '../styles/component/home/Templates.css'
import '../styles/component/home/TrustedBy.css'

import '../styles/component/dashboard/Cluster.css'
import '../styles/component/dashboard/ClusterList.css'
import '../styles/component/dashboard/ConfigMap.css'
import '../styles/component/dashboard/Deployment.css'
import '../styles/component/dashboard/Domain.css'
import '../styles/component/dashboard/DomainList.css'
import '../styles/component/dashboard/GenericResource.css'
import '../styles/component/dashboard/Ingress.css'
import '../styles/component/dashboard/Platform.css'
import '../styles/component/dashboard/ReplicaSet.css'
import '../styles/component/dashboard/ResourceList.css'
import '../styles/component/dashboard/Secret.css'
import '../styles/component/dashboard/Service.css'
import '../styles/component/dashboard/StatefulSet.css'
import '../styles/component/dashboard/TemplateList.css'
import '../styles/component/dashboard/VolumeClaim.css'

import '../styles/component/dashboard/container/ContainerExec.css'
import '../styles/component/dashboard/container/ContainerLogs.css'
import '../styles/component/dashboard/deployment/DeploymentEditor.css'
import '../styles/component/dashboard/repobuilder/Repo.css'
import '../styles/component/dashboard/repobuilder/RepoBuild.css'
import '../styles/component/dashboard/repobuilder/RepoBuildTarget.css'
import '../styles/component/dashboard/repobuilder/RepoList.css'
import '../styles/component/dashboard/repobuilder/RepoPicker.css'
import '../styles/component/admin/AdminFindUser.css'

import '../styles/component/dashboard/cluster/ClusterDetails.css'
import '../styles/component/dashboard/cluster/ClusterFeatures.css'
import '../styles/component/dashboard/cluster/ClusterNamespace.css'
import '../styles/component/dashboard/cluster/ClusterNamespaceManager.css'
import '../styles/component/dashboard/cluster/ClusterNodes.css'
import '../styles/component/dashboard/cluster/ClusterSettings.css'
import '../styles/component/dashboard/cluster/ClusterUser.css'
import '../styles/component/dashboard/cluster/ClusterUserManager.css'

import '../styles/util/Checkbox.css'
import '../styles/util/CodeHighlighter.css'
import '../styles/util/ContextSelector.css'
import '../styles/util/DeprecatedButton.css'
import '../styles/util/Footer.css'
import '../styles/util/HeaderToolbar.css'
import '../styles/util/KubeSailMarkdown.css'
import '../styles/util/MailingListSignup.css'
import '../styles/util/RadioItem.css'
import '../styles/util/RadioTabGroup.css'
import '../styles/util/Sidebar.css'
import '../styles/util/StaticYamlViewer.css'
import '../styles/util/StyleGuide.css'
import '../styles/util/SystemStatus.css'
import '../styles/util/TemplateResult.css'
import '../styles/util/TemplateSearch.css'
import '../styles/util/Tooltip.css'
import '../styles/util/YamlEditor.css'
import '../styles/util/YamlEditorMonacoStyles.css'
import '../styles/util/Notifications.css'

const SystemStatus = dynamic(() => import('../util/SystemStatus'))
const Footer = dynamic(() => import('../util/Footer'))
const YamlEditor = dynamic(() => import('../util/YamlEditor'), {
  loading: () => <p>Editor loading ...</p>,
  ssr: false,
})
const EditorOpenTab = dynamic(() => import('../components/EditorOpenTab'), {
  ssr: false,
})

let initialStartup = false
export default function App({ Component, pageProps }) {
  const [_newData, setNewData] = useState(null)
  const router = useRouter()

  const gaKey = process.env.NEXT_PUBLIC_GA_KEY
  let gaInitialized = false

  let ReactGA
  const initializeGA = async profile => {
    if (!gaKey || gaInitialized || typeof window === 'undefined') {
      return
    }
    ReactGA = await import('react-ga')
    ReactGA.initialize(gaKey, { gaOptions: { userId: profile && profile.id } })
    ReactGA.pageview(window.location.pathname + window.location.search)
    ReactGA.set({ version: COMMIT_HASH })
    gaInitialized = true
  }
  useEffect(() => {
    if (ReactGA) ReactGA.pageview(window.location.pathname + window.location.search)
  }, [router.asPath])

  const handleRedirect = () => {
    if (typeof window === 'undefined') return
    try {
      const redirect = window.localStorage.getItem(REDIRECT_KEY)
      if (redirect) {
        window.localStorage.removeItem(REDIRECT_KEY)
        log.debug('handleRedirect: redirecting to', { redirect: WWW_TARGET + redirect })
        window.location = WWW_TARGET + redirect
        return true
      }
    } catch (err) {
      console.error('handleRedirect failed:', err)
    }
  }

  const handleNewResourceQuery = async profile => {
    const { data, stopRedirectLoop } = parseUrlParams()
    if (!data || (window === 'undefined' && window.location.pathname !== '/new')) {
      return
    }

    if (!isValidProfile(profile)) {
      if (stopRedirectLoop) {
        toast({ type: 'error', msg: 'You must sign in to create this Deployment' })
        return
      }
      if (typeof window !== 'undefined') {
        try {
          window.localStorage.setItem(REDIRECT_KEY, `/new?data=${data}&stopRedirectLoop=true`)
          log.debug('handleNewResourceQuery: redirecting to', `${API_TARGET}/login`)
          window.location = `${API_TARGET}/login`
        } catch (err) {
          console.error('Failed to redirect to new resource:', err)
        }
      }
    }
    try {
      setNewData(JSON.parse(data))
    } catch {
      toast({ type: 'error', msg: 'Failed to parse new document data' })
    }
  }

  useEffect(() => {
    const startup = async () => {
      if (initialStartup) return
      initialStartup = true
      if (typeof window !== 'undefined' && COMMIT_HASH !== 'dev') {
        const Rollbar = (await import('rollbar')).default
        window.rollbar = new Rollbar({
          accessToken: ROLLBAR_ACCESS_TOKEN,
          captureUncaught: true,
          captureUnhandledRejections: true,
          enabled: COMMIT_HASH !== 'dev',
          environment: process.env.NODE_ENV,
          client: {
            javascript: {
              code_version: COMMIT_HASH,
              source_map_enabled: true,
              guess_uncaught_frames: true,
            },
          },
        })
      }

      const { listenPort } = parseUrlParams()
      if (listenPort) {
        Cookies.set(LISTEN_PORT_COOKIE_NAME, listenPort, {
          expires: new Date(new Date().getTime() + 30 * 60 * 1000),
        })
      }

      log.debug('fetchProfile: start')
      const profile = await fetchProfile()
      if (isValidProfile(profile)) {
        const socket = await createSocket()
        socket.on('disconnect', () => log.warn('Socket disconnected!'))
        const { clusterAddress, namespace } = parseUrlParams()
        if (clusterAddress && namespace) {
          log.debug('fetchProfile: setCurrentContext', { namespace, clusterAddress })
          setCurrentContext(namespace, clusterAddress)
        } else {
          log.debug('fetchProfile: fetchAllDocs', {
            namespace,
            clusterAddress,
            currentContext: profile.currentContext,
          })
          if (profile.currentContext.address) {
            await fetchDomains()
            fetchAllDocs().then(() => store.dispatch({ type: 'STOP_FETCHING' }))
          } else {
            store.dispatch({ type: 'STOP_FETCHING' })
          }
        }
      } else {
        log.debug('Profile object is invalid', { profile })
      }
      if (handleRedirect()) return
      await handleNewResourceQuery(profile)
      await initializeGA(profile)
      if (typeof window !== 'undefined') {
        window.__getState = () => store.getState()
      }
    }
    startup()
  }, [])

  return (
    <Provider store={store}>
      <InnerApp Component={Component} pageProps={pageProps} />
    </Provider>
  )
}

const InnerApp = ({ Component, pageProps }) => {
  const showingEditor = useSelector(state => state.editor.showing)
  const toastStack = useSelector(state => state.toastStack)

  const toastMessages = toastStack.map(toast => toast.msg)
  const toasts =
    toastStack.length > 0 ? (
      <div className="toasts">
        {toastStack
          .filter(function onlyUnique(toast, index) {
            return toastMessages.lastIndexOf(toast.msg) === index
          })
          .map(toast => {
            const msgCount = toastMessages.filter(msg => msg === toast.msg).length
            const toastClick = () =>
              store.dispatch({
                type: 'TOAST',
                toastStack: toastStack.filter(({ id }) => id !== toast.id),
              })
            const type = toast.type || 'info'
            return (
              <div
                key={toast.id}
                className={`toast ${type}`}
                onClick={type === 'info' && !toast.persistent ? toastClick : null}
              >
                <div className="message">
                  <div className="message-title">
                    {msgCount > 1 ? <div className="toast-multiple">x{msgCount}</div> : null}
                    <span>{toast.msg}</span>
                  </div>
                  {toast.subMsg ? <div className="sub-message">{toast.subMsg}</div> : null}
                </div>
                {toast.persistent && !toast.hideDismissButton ? (
                  <div className="dismiss-container">
                    <div className="dismiss" onClick={toastClick}>
                      ok
                    </div>
                  </div>
                ) : null}
              </div>
            )
          })}
      </div>
    ) : null

  return (
    <div className={`App ${showingEditor ? 'split' : 'full'}`}>
      <HeaderToolbar />
      {toasts}
      <SystemStatus />
      <Component {...pageProps} />
      <EditorOpenTab />
      <div className="fullscreen-editor">{showingEditor ? <YamlEditor /> : null}</div>
      <Footer />
    </div>
  )
}
