import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import ReactGA from 'react-ga'
import bytes from 'bytes'
import { FaSmile, FaBell, FaRegBell as FaBellEmpty } from 'react-icons/fa'

import { API_TARGET } from '../config'
import { apiFetch, toast, log } from '../util'

const OutlineLogoIconOnly = dynamic(() => import('../icons').then(i => i.OutlineLogoIconOnly))
const TextareaAutosize = dynamic(() => import('react-textarea-autosize'))
const Search = dynamic(() => import('../components/Search'), { ssr: false })
const Button = dynamic(() => import('../components/Button'))
const UsageIndicator = dynamic(() => import('../components/UsageIndicator'), { ssr: false })
const Avatar = dynamic(() => import('../components/Avatar'), { ssr: false })
const ContextSelector = dynamic(() => import('../util/ContextSelector'), { ssr: false })
const Sidebar = dynamic(() => import('./Sidebar'))

class HeaderToolbar extends Component {
  state = {
    expanded: false,
    expanding: false,
    contextsExpanded: false,
    feedbackOpen: false,
    showingInternal: [],
  }

  // TODO expanded onupdate

  avatarClick = () => {
    if (!this.state.expanded) ReactGA.event({ category: 'User', action: 'Avatar click' })
    this.setState({ expanded: !this.state.expanded, expanding: true })
    const handleClick = e => {
      try {
        if ((this.menu && this.menu.contains(e.target)) || e.target.className.includes('avatar')) {
          return
        }
      } catch {
        return
      }
      this.setState({ expanded: false })
      window.setTimeout(() => this.setState({ expanding: false }), 250) // 250ms is the "transition: transform 250ms" css time
      document.body.removeEventListener('click', handleClick)
    }
    document.body.addEventListener('click', handleClick)
  }

  sendFeedback = async () => {
    let emoji = 'no-emoji'
    switch (this.state.emoji) {
      case '😍':
        emoji = 'heart-eyes'
        break
      case '😄':
        emoji = 'happy'
        break
      case '🤔':
        emoji = 'thinking'
        break
      case '😔':
        emoji = 'sad'
        break
    }
    this.setState({ feedbackOpen: false })
    const { status } = await apiFetch('/feedback', {
      method: 'POST',
      body: { emoji, feedback: this.state.feedback },
    })
    if (status !== 200) {
      return toast({ type: 'error', msg: 'Issue submitting feedback', subMsg: status.toString() })
    }
    toast({ type: 'info', msg: 'Feedback submitted!' })
  }

  renderFeedbackForm = () => {
    return (
      <div className="feedback-form" ref={node => (this.feedbackForm = node)}>
        <TextareaAutosize
          onChange={e => this.setState({ feedback: e.target.value })}
          ref={node => (this.feedbackInput = node)}
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Feedback..."
          value={this.state.feedback}
        />
        <div className="controls">
          <div className="emoji">
            {this.state.emoji || <FaSmile className="fa" />}
            <div className="emoji-drawer">
              <div className="emoji-item" onClick={() => this.setState({ emoji: '😍' })}>
                😍
              </div>
              <div className="emoji-item" onClick={() => this.setState({ emoji: '😄' })}>
                😄
              </div>
              <div className="emoji-item" onClick={() => this.setState({ emoji: '🤔' })}>
                🤔
              </div>
              <div className="emoji-item" onClick={() => this.setState({ emoji: '😔' })}>
                😔
              </div>
            </div>
          </div>
          <Button className="send small" onClick={() => this.sendFeedback()}>
            Send
          </Button>
        </div>
      </div>
    )
  }

  feedbackClick = () => {
    this.setState(
      { feedbackOpen: true },
      () =>
        this.feedbackInput &&
        typeof this.feedbackInput.focus === 'function' &&
        this.feedbackInput.focus()
    )
    const handleClick = e => {
      if (this.feedbackForm && this.feedbackForm.contains(e.target)) {
        return
      }
      this.setState({ feedbackOpen: false })
      document.body.removeEventListener('click', handleClick)
    }
    document.body.addEventListener('click', handleClick)
  }

  render() {
    const { feedbackOpen, expanded, expanding } = this.state
    const { profile, router, loadingProfile, metricsData } = this.props
    const LinkIcon = router.pathname === '/dashboard/notifications' ? FaBell : FaBellEmpty

    const links = (
      <div className="links translate">
        {profile ? (
          <Link
            title="View notifications"
            as="/dashboard/notifications"
            href="/dashboard/notifications"
            passHref
          >
            <div className="link">
              <LinkIcon />
            </div>
          </Link>
        ) : null}
        {/* This currently doesn't work */}
        {/* <div
          title="Click here to provide feedback"
          className="link feedback"
          onClick={this.feedbackClick}
        >
          Feedback
        </div> */}
        <Link title="Read the blog and checkout what we're up to!" className="link" href="/blog">
          Blog
        </Link>
        <a
          title="View our documentation"
          className="link"
          target="_blank"
          href="https://docs.kubesail.com"
          rel="noreferrer nofollow"
        >
          Docs
        </a>
        {profile && profile.tier === 'free' ? (
          <Link
            title="View our plans and upgrade to get more from KubeSail!"
            className="link"
            href="/pricing"
          >
            Upgrade
          </Link>
        ) : null}
        {feedbackOpen ? this.renderFeedbackForm() : null}
      </div>
    )

    if (
      (this.props.router.pathname === '/' && !profile) ||
      (this.props.router.pathname.startsWith('/pricing') && !profile) ||
      this.props.router.pathname.startsWith('/login') ||
      this.props.router.pathname.startsWith('/blog') ||
      this.props.router.pathname.startsWith('/homepage') ||
      this.props.router.pathname === '/'
    ) {
      return null
    }

    let logoTarget = '/homepage'
    let logoTargetAs = '/homepage'
    if (
      profile &&
      !(
        this.props.router.pathname.startsWith('/pricing') ||
        this.props.router.pathname.startsWith('/blog')
      )
    ) {
      logoTarget = '/dashboard/[[...params]]'
      logoTargetAs = '/dashboard'
    }

    let memoryUsage
    let cpuUsage
    const lastMemUsage = []
    const lastCPUUsage = []

    if (metricsData && metricsData.nodes) {
      Object.keys(metricsData.nodes).forEach(n => {
        const memUsed = bytes(metricsData.nodes[n].memory[0])
        const memCapacity = bytes(metricsData.nodes[n].capacity.memory)
        lastMemUsage.push((memUsed / memCapacity) * 100)
        let cpuUsed = metricsData.nodes[n].cpu[0]
        if (cpuUsed.endsWith('n')) cpuUsed = parseInt(cpuUsed.replace(/n$/, ''), 10)
        else if (cpuUsed.endsWith('u')) cpuUsed = parseInt(cpuUsed.replace(/u$/, ''), 10) * 1000
        else if (cpuUsed.endsWith('m')) cpuUsed = parseInt(cpuUsed.replace(/m$/, ''), 10) * 1000000
        const cpuCapacity = parseInt(metricsData.nodes[n].capacity.cpu, 10) * 1000000000
        lastCPUUsage.push((cpuUsed / cpuCapacity) * 100)
      })
      memoryUsage = lastMemUsage.reduce((a, b) => a + b) / lastMemUsage.length
      cpuUsage = lastCPUUsage.reduce((a, b) => a + b) / lastCPUUsage.length
    }
    if ((lastMemUsage.length > 0 && isNaN(memoryUsage)) || memoryUsage > 100 || memoryUsage < 0) {
      log.error('Invalid memory usage calculated:', { memoryUsage, lastMemUsage, metricsData })
      memoryUsage = 0
    }
    if ((lastCPUUsage.length > 0 && isNaN(cpuUsage)) || cpuUsage > 100 || cpuUsage < 0) {
      log.error('Invalid CPU usage calculated:', { cpuUsage, lastCPUUsage, metricsData })
      cpuUsage = 0
    }

    let redirectTo = ''
    if (typeof window !== 'undefined') {
      redirectTo = window.location
    }

    return (
      <div className={`HeaderToolbar`}>
        <div
          className={`hide-on-desktop ${
            this.props.editor.showing ? 'editor-open' : 'editor-closed'
          }`}
        >
          <Sidebar small={true} collapsable={true}>
            {links}
          </Sidebar>
        </div>
        <div
          className={`hide-on-mobile ${
            this.props.editor.showing ? 'editor-open' : 'editor-closed'
          }`}
        >
          <Link as={logoTargetAs} href={logoTarget} passHref>
            <div className="kubesail-logo">
              <OutlineLogoIconOnly />
            </div>
          </Link>
        </div>
        {profile ? <ContextSelector className="context-selector" /> : null}
        <Search />
        {memoryUsage ? (
          <Link
            as={`/clusters/${profile?.currentContext?.address || ''}/nodes`}
            href={'/clusters/[[...params]]'}
            passHref
          >
            <div
              className={`heads-up-stats ${
                this.props.editor.showing ? 'editor-open' : 'editor-closed'
              }`}
            >
              <UsageIndicator
                used={cpuUsage}
                total={100}
                title={`Using approximately ${Math.round(cpuUsage)}% CPU`}
                label="CPU"
              />
              <UsageIndicator
                used={memoryUsage}
                total={100}
                title={`Using approximately ${Math.round(memoryUsage)}% memory`}
                label="MEM"
              />
            </div>
          </Link>
        ) : null}
        <div className="hide-on-mobile">{links}</div>
        {profile ? (
          <div className="profile">
            <div className="profile-status">
              <Avatar size={42} onClick={this.avatarClick} />
            </div>
            <div className={`menu ${expanded ? 'expanded' : ''} ${expanding ? 'expanding' : ''}`}>
              <div className="close">close</div>
              <div ref={node => (this.menu = node)}>
                <div className="wrapper">
                  <div className="overview">
                    <Avatar size={80} />
                    <div className="details">
                      <h2>{profile.username || 'Pick a username!'}</h2>
                      <h3>{profile.email}</h3>
                      <Button
                        tag="Link"
                        href="/account"
                        className="small light"
                        onClick={() => this.setState({ expanded: false })}
                      >
                        Account Settings
                      </Button>
                    </div>
                  </div>
                  <div className="logout">
                    <Button className="small light" href={`${API_TARGET}/logout`}>
                      Logout
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : loadingProfile ? null : (
          <a
            href={['/login', redirectTo ? `?redirect=${encodeURIComponent(redirectTo)}` : null]
              .filter(Boolean)
              .join('')}
            className="right small light call-to-action"
            style={{
              minWidth: '4rem',
              marginLeft: '10px',
              textAlign: 'center',
              textDecoration: 'none',
            }}
          >
            Sign up
          </a>
        )}
      </div>
    )
  }
}

export default connect(({ profile, loadingProfile, metricsData, editor }) => {
  return { profile, loadingProfile, metricsData, editor }
})(withRouter(HeaderToolbar))
