import Cookies from 'cookies-js'
import * as loglevel from 'loglevel'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'

import store from '../store'
import fetchAllDocs, {
  fetchDomains,
  handleKubeEvent,
  handleKubeSailEvent,
  fetchNotifications,
  establishWatch,
} from './fetch'
import {
  KUBESAIL_NAMESPACE_KEY,
  KUBESAIL_CLUSTER_ADDRESS_KEY,
  KUBESAIL_LAST_USED_CONTEXT_KEY,
  WSS_TARGET,
  API_TARGET,
  WWW_TARGET,
  COMMIT_HASH,
  LOG_LEVEL,
  NODE_ENV,
} from '../config'

if (!global.$RefreshReg$) global.$RefreshReg$ = () => {}
if (!global.$RefreshSig$) global.$RefreshSig$ = () => () => {}
let socketioClient

let logLevel = LOG_LEVEL
try {
  logLevel = window.localStorage.getItem('LOG_LEVEL') || LOG_LEVEL
} catch (err) {}
export const log = loglevel

if (typeof window !== 'undefined') {
  window.log = log
  window.setLogLevel = function (level) {
    log.setLevel(level)
    window.localStorage.setItem('LOG_LEVEL', level)
  }
}

log.setLevel(logLevel)

export function verifyCluster({ friendlyName, agentKey, agentSecret, gatewayAddress }) {
  return new Promise((resolve, reject) => {
    if (!friendlyName) return reject(new Error('no name provided'))
    apiFetch('/clusters/pending/verify', {
      method: 'POST',
      body: { friendlyName, agentKey, agentSecret, gatewayAddress },
    }).then(({ status, json }) => {
      if (status !== 200) {
        toast({
          type: 'error',
          msg: 'Failed to verify pending cluster',
          subMsg: `${json.error || friendlyName || ''}`,
        })
        return reject(new Error(json.error || 'failed to verify cluster'))
      } else {
        if (window.__EVENTSTREAM) {
          window.__EVENTSTREAM.close()
          setTimeout(() => {
            createSocket(true)
            setTimeout(() => {
              resolve(json)
            }, 1500)
          }, 1500)
        }
      }
    })
  })
}

export function lazyRetry(fn, retriesLeft = 3, interval = 500) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
            return
          }
          lazyRetry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export function parseResourceError(resource) {
  const { error, name, _errCode, body } = resource
  if (!error) return {}
  const kind = body?.kind
  const parts = error.split(':')
  let title = parts.shift() || ''
  const key = (parts.shift() || '').trim().replace(/^\[/, '')
  const errorType = (parts.shift() || '').trim()
  const errorMsg = (parts.shift() || '').replace(/\\t/g, '  ').trim()

  let humanReadable = ''

  if (kind === 'PersistentVolumeClaim') {
    if (errorType === 'Forbidden' && errorMsg.includes('spec is immutable after creation except')) {
      title = `PVC/${name} is immutable`
      humanReadable = 'Only the "resources.requests" field can be modified after creation'
    }
  } else if (kind === 'Ingress') {
    if (errorType === 'Invalid value') {
      humanReadable = `key "${key}" has invalid value ${errorMsg}`
    }
  }

  if (!humanReadable) humanReadable = resource.error

  return { title, body: humanReadable }
}

let pollingForAgentHealth = []
export const fetchAgentHealth = async (
  agentKey /*: string */,
  force = false,
  retries = 0,
  onConnected
) => {
  if (!force && pollingForAgentHealth.includes(agentKey)) return
  if (!pollingForAgentHealth.includes(agentKey)) pollingForAgentHealth.push(agentKey)

  log.debug('fetchAgentHealth!', { force, retries })
  const { status, json } = await apiFetch(`/agent/health/${agentKey}`)

  if (status === 404) return

  if (status >= 500) {
    setTimeout(() => {
      fetchAgentHealth(agentKey, true, ++retries, onConnected)
    }, (3 + retries) * 1000)
    return toast({
      type: 'error',
      msg: `Failed checking cluster health. Please contact support@kubesail.com`,
      err: status,
    })
  }

  let cluster
  const state = store.getState()
  store.dispatch({
    type: 'SET_PROFILE',
    profile: {
      ...state.profile,
      clusters: state.profile.clusters.map(c => {
        if (c.agentKey === agentKey) {
          cluster = { ...c, ...json }
          log.debug('Agent Health Data', { cluster })
          return cluster
        }
        return c
      }),
    },
  })

  if (!json.connected) {
    setTimeout(() => {
      fetchAgentHealth(agentKey, true, ++retries, onConnected)
    }, (3 + retries) * 1000)
  } else {
    pollingForAgentHealth = pollingForAgentHealth.filter(k => k !== agentKey)
    if (onConnected) onConnected(cluster)
  }
}
if (typeof window !== 'undefined') {
  window.__fetchAgentHealth = fetchAgentHealth
}

const hiddenNs = ['kubesail-agent', 'ingress-nginx', 'cert-manager', 'rook-ceph']
export function filterNamespace(simple) {
  return ns => {
    if (!ns || !ns.name || typeof ns.name !== 'string') return false
    const system =
      ns.name.startsWith('kube-') ||
      ns.name.startsWith('img--') ||
      ns.name.startsWith('system--') ||
      hiddenNs.includes(ns.name)
    return simple ? !system : system
  }
}

export function getCurrentContext(clusters, fullClusterDetails = false) {
  let context
  let namespace
  let address
  let cluster
  try {
    address = window.localStorage.getItem(KUBESAIL_CLUSTER_ADDRESS_KEY) || ''
    address = address.startsWith('https://') ? address.substr(8) : address
    if (!address || address === 'undefined') {
      window.localStorage.removeItem(KUBESAIL_CLUSTER_ADDRESS_KEY)
      address = undefined
    }
    cluster = clusters.find(cluster => cluster.address === address)
    namespace = (window.localStorage.getItem(KUBESAIL_NAMESPACE_KEY) || '').toLowerCase()
    // Fix for badly stored namespaces
    if (!namespace.match(/^[a-z0-9-]+$/) && cluster && cluster.namespaces) {
      namespace = cluster.namespaces[0].name
    }
  } catch (err) {
    log.warn('getCurrentContext unable to fetch from localStorage', { errMsg: err.message })
  }
  const foundCluster = cluster && clusters.find(c => c.address === cluster.address)
  if (
    cluster &&
    namespace &&
    (cluster.role === 'admin' ||
      (foundCluster && foundCluster.namespaces.find(ns => ns.name === namespace)))
  ) {
    context = { address: cluster.address, namespace }
  } else {
    context = {
      address: clusters[0].address,
      namespace: get(clusters, '[0].namespaces[0].name') || 'default',
    }
  }
  log.debug('getCurrentContext:', context)
  if (fullClusterDetails) {
    return { ...context, ...(cluster || {}) }
  }
  return context
}

export function setCurrentContext(namespace, address) {
  log.debug('setCurrentContext:', { namespace, address })
  namespace = namespace.toLowerCase()
  const state = store.getState()
  try {
    window.localStorage.setItem(KUBESAIL_NAMESPACE_KEY, namespace)
    window.localStorage.setItem(KUBESAIL_CLUSTER_ADDRESS_KEY, address)

    // Keep track of the last used contexts so we can sort the context selector list
    let lastUsedContexts
    try {
      lastUsedContexts = JSON.parse(window.localStorage.getItem(KUBESAIL_LAST_USED_CONTEXT_KEY))
    } catch {}
    if (!lastUsedContexts) lastUsedContexts = []
    if (!Array.isArray(lastUsedContexts)) {
      log.debug('setCurrentContext: Invalid lastUsedContexts', { lastUsedContexts })
      lastUsedContexts = []
    }
    if (lastUsedContexts.length > 10) lastUsedContexts.pop()
    lastUsedContexts.unshift({ address, namespace })
    window.localStorage.setItem(KUBESAIL_LAST_USED_CONTEXT_KEY, JSON.stringify(lastUsedContexts))
  } catch (err) {
    log.debug('setCurrentContext: error', { errMsg: err.message })
  }
  store.dispatch({
    type: 'SET_PROFILE',
    profile: { ...state.profile, currentContext: { namespace, address } },
  })
  store.dispatch({ type: 'DOCS_UPDATE', docs: state.docs.filter(doc => doc.kind !== 'Node') })
  store.dispatch({ type: 'SET_DISK_STATS', diskStats: null })

  apiFetch('/set-context', { method: 'POST' }).then(() => {
    log.debug('setCurrentContext: triggering refetch of all docs')
    fetchAllDocs()
    fetchDomains()
    const cluster = state.profile.clusters.find(
      c => state.profile?.currentContext?.address === c.address
    )
    if (cluster && cluster.shared === false && cluster.verified && cluster.agentKey) {
      fetchAgentHealth(cluster.agentKey)
    }
  })
}

// Return a list of all docs _except_ these
// If no name is specified, filter out all matching this kind
export const filterOutDoc = (kind, name) => doc => {
  if (!name && doc.kind !== kind) return true
  else if (doc.kind === kind && doc.metadata && doc.metadata.name === name) return false
  else return true
}

// Reverse of filterOutDoc - return a list of all docs matching this query
// If no name is specified, return all matching this kind
export const filterDoc = (kind, name) => doc => {
  if (!name && doc.kind === kind) return true
  else if (doc.kind === kind && doc?.metadata?.name === name) return true
  else return false
}

export function getBaseUnits(value) {
  if (!value) return
  let unit
  if (value.length > 1) {
    unit = value.substr(value.length - 2, value.length)
    unit = unit[1] === 'm' ? 'm' : unit // millicore representations
  }

  const unitMap = {
    m: 1 / 1000, // millicore
    Ki: 1024,
    Mi: 1024 * 1024,
    Gi: 1024 * 1024 * 1024,
    Ti: 1024 * 1024 * 1024 * 1024,
    Pi: 1024 * 1024 * 1024 * 1024 * 1024,
    Ei: 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
  }

  const multiplier = unitMap[unit] ? unitMap[unit] : 1
  return unitMap[unit] ? parseInt(value, 10) * multiplier : parseFloat(value)
}

export function bytesToSize(bytes) {
  if (typeof bytes === 'undefined') return
  if (typeof bytes === 'string') return bytes
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return (
    <>
      {Math.round(bytes / Math.pow(1024, i), 2)}
      <span style={{ fontSize: '0.75rem' }}>{sizes[i]}</span>
    </>
  )
}

// Removes the non-editable properties of Kubernetes YAML configs
export function filterKeys(input, removeKubesailUid) {
  const filter = doc => {
    if (doc && doc.metadata) {
      delete doc.metadata.managedFields
      delete doc.metadata.resourceVersion
      delete doc.metadata.uid
      delete doc.metadata.selfLink
      delete doc.metadata.generation
      delete doc.metadata.namespace
      delete doc.metadata.creationTimestamp
      delete doc.status
      if (doc.metadata.annotations) {
        delete doc.metadata.annotations['deployment.kubernetes.io/revision']
        delete doc.metadata.annotations['kubectl.kubernetes.io/last-applied-configuration']
        if (removeKubesailUid) delete doc.metadata.annotations.kubesailEditorUid
        if (
          typeof doc.metadata.annotations === 'object' &&
          Object.keys(doc.metadata.annotations).length === 0
        ) {
          delete doc.metadata.annotations
        }
      }
      if (doc.kind && doc.kind.toLowerCase() === 'deployment' && doc.spec) {
        if (doc.spec.template && doc.spec.template.metadata) {
          delete doc.spec.template.metadata.creationTimestamp
        }
      }
      if (doc.kind && doc.kind.toLowerCase() === 'service' && doc.spec) {
        delete doc.spec.clusterIP
        delete doc.spec.clusterIPs
      }
      if (doc.kind && doc.kind.toLowerCase() === 'secret' && doc.stringData && doc.data) {
        delete doc.data
      }
      if (doc.kind && doc.kind.toLowerCase() === 'persistentvolumeclaim' && doc.spec) {
        delete doc.metadata.finalizers
        if (doc.metadata.annotations) {
          delete doc.metadata.annotations['pv.kubernetes.io/bind-completed']
          delete doc.metadata.annotations['pv.kubernetes.io/bound-by-controller']
          delete doc.metadata.annotations['volume.beta.kubernetes.io/storage-provisioner']
        }
      }
    }
    return doc
  }

  if (Array.isArray(input)) {
    return cloneDeep(input).map(filter)
  } else {
    return filter(cloneDeep(input))
  }
}

export function signOutHandler() {
  try {
    window.localStorage.removeItem('INTEGRATION_API_KEY')
    window.localStorage.removeItem('INTEGRATION_API_SECRET')
    // window.localStorage.removeItem('INTEGRATION_NAMESPACE')
    // window.localStorage.removeItem('INTEGRATION_CLUSTER_ADDRESS')
  } catch {}
}

export async function createSocket(force = false) {
  if (window.__EVENTSTREAM && !force) return window.__EVENTSTREAM

  let target = WSS_TARGET

  try {
    const key = window.localStorage.getItem('INTEGRATION_API_KEY')
    const secret = window.localStorage.getItem('INTEGRATION_API_SECRET')
    if (key && secret) {
      target = target + `?token=${key}|${secret}`
    }
  } catch {}

  log.debug('createSocket', { force, target })
  if (!socketioClient) socketioClient = await import('socket.io-client')

  window.__EVENTSTREAM = socketioClient.default(target, {
    timeout: 5000,
    reconnectionDelayMax: 3000,
    secure: true,
    withCredentials: true,
  })

  window.__EVENTSTREAM.on('error', err => {
    if (err === 'Unauthorized') {
      console.error('Unauthorized received from Websocket!', { err })
      toast({
        type: 'error',
        msg: `Failed to connect to websocket - logging out!`,
        err,
      })
      signOutHandler()
    } else if (err.type !== 'TransportError') {
      console.error('Unknown error!', err)
    }
  })

  window.__EVENTSTREAM.on('connect', () => {
    log.debug('Socket connected')
    // Re-fetch notifications incase we missed any
    fetchNotifications()
    if (window._agentKubeWatch && typeof window._agentKubeWatch === 'object') {
      establishWatch(window._agentKubeWatch.agentKey, window._agentKubeWatch.namespace)
    }
  })

  window.__EVENTSTREAM.on('connect_error', error => {
    log.warn('Socket connection error!', error)
  })

  window.__EVENTSTREAM.on('connect_timeout', timeout => {
    log.warn('Socket connection timeout!', timeout)
  })

  // events are events from Kubernetes
  window.__EVENTSTREAM.off('events', handleKubeEvent)
  window.__EVENTSTREAM.off('kubesailEvent', handleKubeSailEvent)
  window.__EVENTSTREAM.on('events', handleKubeEvent)
  window.__EVENTSTREAM.on('kubesailEvent', handleKubeSailEvent)

  return window.__EVENTSTREAM
}

export function notify(msg, body = null) {
  if ('Notification' in window && window.Notification.permission === 'granted') {
    const _notification = new window.Notification(
      msg,
      Object.assign(
        {
          icon: '/android-chrome-512x512.png',
        },
        body ? { body } : {}
      )
    )
  }
}

export function parseUrlParams(url /*: string */ = '') {
  if (!url && typeof window !== 'undefined') url = window.location.search
  const query = url.substr(1)
  const result = {}
  query.split('&').forEach(function (part) {
    const item = part.split('=')
    result[item[0]] = decodeURIComponent(item[1])
  })
  return result
}

export const isValidProfile = (profile /*: any */) /*: boolean */ => {
  return profile && typeof profile === 'object' && Array.isArray(profile.clusters)
}

let gzip
export const apiFetch = async (
  targetUri,
  options = { headers: {} },
  ksOptions = { parseJson: true }
) => {
  let namespace
  let clusterAddress

  const profile = store.getState().profile
  const uri = API_TARGET + targetUri
  const authorizationHeader = {}

  try {
    const apiKey = window.localStorage.getItem('INTEGRATION_API_KEY')
    const apiSecret = window.localStorage.getItem('INTEGRATION_API_SECRET')
    if (apiKey && apiSecret) {
      authorizationHeader.Authorization = 'Basic ' + window.btoa(apiKey + ':' + apiSecret)
    }
  } catch {}

  if (isValidProfile(profile)) {
    namespace = profile?.currentContext?.namespace
    clusterAddress = profile?.currentContext?.address
  } else {
    try {
      namespace = window.localStorage.getItem('INTEGRATION_NAMESPACE')
      clusterAddress = window.localStorage.getItem('INTEGRATION_CLUSTER_ADDRESS')
    } catch {}
  }

  if (!options.headers) options.headers = {}
  options.headers['x-commit-hash'] = COMMIT_HASH

  let body = options.body
  if (!options.form) {
    options.headers['content-type'] = 'application/json'
    if (typeof options.body === 'object') {
      const stringBody = JSON.stringify(options.body)
      if (stringBody.length > 2000) {
        options.headers['content-encoding'] = 'gzip'
        if (!gzip) {
          const pako = await import('pako')
          gzip = pako.gzip
        }
        body = await gzip(stringBody)
      } else {
        body = stringBody
      }
    }
  }

  options.headers['x-kubesail-namespace'] = options.namespace || namespace
  options.headers['x-kubesail-cluster-address'] = options.clusterAddress || clusterAddress

  const targetURL = new URL(uri)
  targetURL.search = new URLSearchParams(options.query)

  const opts = Object.assign({}, options, {
    body,
    credentials: 'include',
    headers: Object.assign({}, authorizationHeader, options.headers),
  })
  let json = {}
  let status
  let headers = {}
  let res
  const target = targetURL.toString()

  try {
    res = await window.fetch(targetURL, opts)
    status = res.status
    headers = res.headers
  } catch (err) {
    log.warn(`apiFetch: error: ${targetURL} ${err.status || ''} ${err.message}`)
    return { error: err, status: err.status, json }
  }
  if (!res) throw new Error('API Fetch - no response')
  else if (status === 204) return { status, json: {} }
  else {
    if (options.onData) {
      const body = await res.body
      const reader = body.getReader()
      const decoder = new TextDecoder('utf-8')
      const read = () => {
        reader.read().then(({ done, value }) => {
          if (value) options.onData(decoder.decode(value))
          if (!done) read()
        })
      }
      read()
    } else {
      if (ksOptions.parseJson) {
        try {
          json = await res.json()
        } catch (err) {
          // Ignore abort errors, we don't care.
          if (err.name === 'AbortError') return { status }
          console.error('apiFetch() failed to parse JSON!', {
            status: res.status,
            target,
            errCode: err.code,
            errMsg: err.message,
            errName: err.name,
            hasAbortController: !!window.AbortController,
          })
          // If the API simply returned invalid JSON, don't crash the application (Backend should also print an error)
          if (err.name !== 'TypeError' && res.status !== 200) throw err
        }
      }
    }
  }
  return { status, json, headers, res }
}
if (typeof window !== 'undefined') {
  window.apiFetch = apiFetch
}

export const toast = opts => {
  const toastStack = store.getState().toastStack
  if (typeof opts !== 'object') throw new Error('Toast must be called with object params')
  const { type = 'info', msg, subMsg, err = null, timeout = 8000, hideDismissButton = false } = opts
  const persistent = typeof opts.persistent === 'boolean' ? opts.persistent : type === 'error'
  if (type === 'error') {
    console.error(
      'Toast Error | ' + msg + ' | ' + (err !== msg ? err || opts.subMsg : ''),
      opts.err
    )
  }
  const id = Math.random()
  store.dispatch({
    type: 'TOAST',
    toastStack: [...toastStack, { type, msg, subMsg, id, persistent, hideDismissButton }],
  })
  if (!document.hasFocus()) {
    notify(`${type === 'error' ? 'Error: ' : ''}${msg}`)
  }
  if (!persistent) {
    window.setTimeout(() => {
      const toastStack = store.getState().toastStack
      store.dispatch({
        type: 'TOAST',
        toastStack: toastStack.filter(toast => toast.id !== id),
      })
    }, timeout)
  }
  return id
}

export async function fetchTemplate({ username, name, revision, noData }) {
  revision = parseInt(revision, 10)
  store.dispatch({ type: 'RESET_TEMPLATE' })
  store.dispatch({ type: 'PENDING_DOCS_UPDATE', pendingDocs: [] })

  const uri = `/templates/${username}/${encodeURIComponent(name)}/${
    revision && typeof revision === 'number' ? revision : ''
  }`
  const query = {}
  if (noData) query.noData = true

  const {
    status,
    json: { templates },
  } = await apiFetch(uri, { query })
  if (![200, 404].includes(status))
    return toast({ type: 'error', msg: 'Failed to fetch template', err: status })
  if (!templates || templates.length === 0) {
    store.dispatch({ type: 'TEMPLATE_UPDATE', fetching: false, invalidUrl: 'Template Not Found' })
    return
  }

  await updateTemplateStoreWithNewTemplate({ name, username, template: templates[0] })

  return templates[0]
}

// This needs to always be consistent, so it can run many many times in a row
// And always return the docs in the same
// It's also important that we dont sort in different places
const importanceOfDocuments = [
  'Deployment',
  'Ingress',
  'PersistentVolumeClaim',
  'ConfigMap',
  'Secret',
  'Service',
  'ReplicaSet',
]
export function sortDocs(docs) {
  return docs.sort((a, b) => {
    // < 0 => a first
    // 0 ... no change
    // > 0 => b first
    const isAppTemplate = c => {
      return (
        (c?.kind === 'Deployment' &&
          (((c || {}).metadata || {}).annotations || {})['kubesail.com/template']) ||
        ''
      )
    }
    const iA = importanceOfDocuments.indexOf(a.kind)
    const iB = importanceOfDocuments.indexOf(b.kind)
    if (isAppTemplate(a) && isAppTemplate(b)) {
      return (a?.metadata?.name || '').localeCompare(b?.metadata?.name || '')
    } else if (isAppTemplate(a)) {
      return -1
    } else if (isAppTemplate(b)) {
      return 1
    } else if (iA < iB) {
      return -1
    } else if (iA === -1) {
      return -1
    } else if (a.kind === b.kind) {
      return (a?.metadata?.name || '').localeCompare(b?.metadata?.name || '')
    } else {
      return 1
    }
  })
}

export async function updateTemplateStoreWithNewTemplate({ name, username, template }) {
  const YAML = await import('yaml')
  const state = store.getState()
  const { data, revision, isPrivate } = template
  store.dispatch({
    type: 'UPDATE_EDITOR',
    isTemplate: true,
    templateUsername: username,
    name,
    revision,
    isPrivate,
    showing: false,
    yaml: data,
  })
  const docs = data ? YAML.parseAllDocuments(data).map(d => d.toJSON()) : []
  // Merge with any existing "real docs" in props.docs
  const templateDocs = sortDocs(
    docs
      .filter(
        d => d && typeof d.kind === 'string' && d.metadata && typeof d.metadata.name === 'string'
      )
      .map(doc =>
        Object.assign(
          {},
          state.docs.find(filterDoc(doc.kind, doc.metadata.name)) || {},
          filterKeys(doc)
        )
      )
  )

  const targetDocs = templateDocs.map(d => {
    return { kind: d.kind, name: d.metadata.name }
  })
  store.dispatch({ type: 'PENDING_DOCS_UPDATE', pendingDocs: templateDocs })
  store.dispatch({ type: 'TARGET_DOCS', targetDocs })
  store.dispatch({
    type: 'TEMPLATE_UPDATE',
    data,
    name,
    username,
    ...template,
    newDescription: template.description,
    revision,
    pendingIsPrivate: isPrivate,
    fetching: false,
    invalidDocCount: docs.length - templateDocs.length,
    templateDocs,
  })
}

export async function uploadImage(path, e) {
  const file = e.target.files[0]
  if (!file) return
  const types = ['image/png', 'image/jpeg', 'image/gif']
  if (!types.includes(file.type)) {
    return { error: 'Sorry, only png, jpg and gif images are allowed!' }
  }
  // Resize the image
  const MAX_WIDTH = 500
  const MAX_HEIGHT = 500
  const canvas = document.createElement('canvas')
  const img = document.createElement('img')
  const reader = new window.FileReader()
  await new Promise(resolve => {
    reader.readAsDataURL(file)
    reader.onload = e => {
      img.src = e.target.result
      resolve()
    }
  })
  await new Promise(resolve => {
    img.onload = () => resolve()
  })
  let ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0)
  let width = img.width
  let height = img.height
  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width
      width = MAX_WIDTH
    }
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height
      height = MAX_HEIGHT
    }
  }
  canvas.width = width
  canvas.height = height
  ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0, width, height)
  const resizedFile = await new Promise(resolve =>
    canvas.toBlob(resizedFile => resolve(resizedFile))
  )
  const data = new window.FormData()
  data.append('file', resizedFile)
  return apiFetch(path, { method: 'POST', body: data, form: true })
}

export function findServicesMatchingDoc(docsToSearch, doc) {
  const containers = get(doc, 'spec.template.spec.containers') || []
  const labels = get(doc, 'spec.template.metadata.labels') || {}
  return docsToSearch
    .filter(doc => doc.kind === 'Service')
    .filter(service => {
      const selector = get(service, 'spec.selector', {})
      const selectorKeys = Object.keys(typeof selector === 'object' ? selector : {})
      const matchingLabels =
        selectorKeys.filter(key => selector[key] === labels[key]).length === selectorKeys.length

      return (
        matchingLabels &&
        containers.find(container => {
          return (Array.isArray(get(container, 'ports')) ? container.ports : [])
            .filter(o => o && typeof o === 'object')
            .find(containerPort => {
              return (Array.isArray(get(service, 'spec.ports')) ? service.spec.ports : []).find(
                servicePort => {
                  return (
                    servicePort.targetPort === containerPort.containerPort ||
                    servicePort.port === containerPort.containerPort ||
                    servicePort.targetPort === containerPort.name
                  )
                }
              )
            })
        })
      )
    })
}

export function findIngressesMatchingServices(docsToSearch, serviceDocs) {
  return docsToSearch
    .filter(doc => doc.kind === 'Ingress')
    .filter(ingress =>
      serviceDocs.find(service => {
        return get(service, 'spec.ports', []).find(port => {
          if (
            get(ingress, 'spec.backend.serviceName', null) === get(service, 'metadata.name') &&
            (get(ingress, 'spec.backend.servicePort', null) === port.port ||
              get(ingress, 'spec.backend.servicePort', null) === port.name)
          ) {
            return true
          }
          return get(ingress, 'spec.rules', []).find(rule => {
            return get(rule, 'http.paths', []).find(path => {
              const serviceName = get(service, 'metadata.name')
              const legacyIngressMatch =
                serviceName === get(path, 'backend.serviceName', null) &&
                (port.port === get(path, 'backend.servicePort', null) ||
                  port.targetPort === get(path, 'backend.servicePort', null))
              const ingressMatch =
                serviceName === get(path, 'backend.service.name', null) &&
                (port.port === get(path, 'backend.service.port.number', null) ||
                  port.port === get(path, 'backend.service.port.name', null) ||
                  port.targetPort === get(path, 'backend.service.port.name', null))
              return legacyIngressMatch || ingressMatch
            })
          })
        })
      })
    )
}

// Returns a specific document by kind and name - returns a deep clone
export async function getDoc(kind, name, realOnly = false) {
  if (!kind || !name) throw new Error('getDoc() requires two arguments, name and kind!')
  const state = store.getState()
  const pendingDoc = state.pendingDocs.find(filterDoc(kind, name))
  const realDoc = state.docs.find(filterDoc(kind, name))
  let doc = pendingDoc || realDoc
  if (realOnly) {
    doc = realDoc
  }
  if (!doc) {
    return
  }
  return JSON.parse(JSON.stringify(doc))
}

// Deletes a resource entirely, from DOCS and PENDING docs
export async function deleteHandler(kind, name, apiVersion) {
  log.debug('deleteHandler', { kind, name, apiVersion })
  const state = store.getState()
  if (!getDoc(kind, name, true)) {
    store.dispatch({
      type: 'PENDING_DOCS_UPDATE',
      pendingDocs: state.pendingDocs.filter(filterOutDoc(kind, name)),
    })
    return
  }
  const { status, json } = await apiFetch(`/resources`, {
    method: 'DELETE',
    query: { kind, name, apiVersion },
  })
  if (status !== 200) {
    toast({
      type: 'error',
      msg: `Could not delete ${name}`,
      subMsg: json && json.error ? json.error : 'Unknown Error',
    })
  } else {
    store.dispatch({
      type: 'DOCS_UPDATE',
      docs: state.docs.filter(filterOutDoc(kind, name)),
    })
  }
}

export function getCluster(profile) {
  if (!profile?.currentContext?.address) return null
  const clusters = profile?.clusters || []
  return clusters.find(c => c.address === profile?.currentContext?.address)
}

export async function upgradeAgent(clusterAddress, targetVersion) {
  toast({
    msg: `Upgrading ${clusterAddress} to v${targetVersion}`,
    subMsg: 'Agent may become unavailable for a few moments...',
  })
  const { status, json } = await apiFetch('/resources', {
    method: 'PATCH',
    namespace: NODE_ENV === 'development' ? 'default' : 'kubesail-agent',
    clusterAddress,
    body: {
      apiVersion: 'apps/v1',
      kind: 'Deployment',
      metadata: { name: 'kubesail-agent' },
      spec: {
        template: {
          spec: {
            containers: [
              {
                name: 'agent',
                image: `kubesail/agent:v${targetVersion}`,
                volumeMounts: [
                  { name: 'host-dbus', mountPath: '/host/run/dbus' },
                  { name: 'pibox-run', mountPath: '/var/run/pibox' },
                  { name: 'opt-kubesail', mountPath: '/opt/kubesail' },
                ],
              },
            ],
            volumes: [
              { name: 'host-dbus', hostPath: { path: '/run/dbus', type: 'DirectoryOrCreate' } },
              {
                name: 'pibox-run',
                hostPath: { path: '/var/run/pibox', type: 'DirectoryOrCreate' },
              },
              {
                name: 'opt-kubesail',
                hostPath: { path: '/opt/kubesail/', type: 'DirectoryOrCreate' },
              },
            ],
            ports: [
              { name: 'metrics', containerPort: 5000 },
              { name: 'http', containerPort: 6000 },
              { name: 'web', containerPort: 4080 },
              { name: 'websecure', containerPort: 4443 },
            ],
          },
        },
      },
    },
  })
  if (status !== 200 || !json || json.errCode) {
    toast({
      type: 'error',
      msg: `Failed to upgrade ${clusterAddress} to v${targetVersion}`,
      subMsg: json.error || 'unknown error',
    })
  } else {
    toast({ type: 'success', msg: `Upgrading ${clusterAddress} to v${targetVersion}!` })
  }
}

export const fetchProfile = async () => {
  let profile
  try {
    if (typeof window === 'undefined') {
      log.debug('fetchProfile: exiting, window is undefined')
      return
    }
    const { error, json, status } = await apiFetch('/profile')
    if (status === 403) {
      if (window.location.pathname !== '/username') {
        log.debug('App fetchProfile: redirecting to /username')
        window.location = '/username'
      }
      store.dispatch({ type: 'SET_PROFILE', profile: json, loadingProfile: false })
      return
    } else if (
      status === 401 &&
      (window.location.pathname !== '/' || window.location.pathname.startsWith('/templates'))
    ) {
      store.dispatch({ type: 'SET_PROFILE', profile: null, loadingProfile: false })
      return signOutHandler()
    } else if (status !== 200 || !isValidProfile(json)) {
      // TODO: Conceptually, we should re-try with a back-off here (if error is TypeError or >500)
      let errorType = 'Server Error'
      if (error && error.name === 'TypeError') errorType = 'Network Error'
      return toast({
        type: 'error',
        msg: `Error fetching your profile: ${errorType}`,
        subMsg: 'Please try again in a moment',
        persistent: false,
      })
    }
    profile = json

    if (profile.lastContext) {
      profile.currentContext = {
        namespace: profile.lastContext.namespace,
        address: profile.lastContext.address,
      }
    } else if (profile.clusters.length > 0) {
      profile.currentContext = getCurrentContext(profile.clusters)
    } else {
      profile.currentContext = { namespace: profile.username }
    }

    if (typeof window !== 'undefined' && window.rollbar) {
      window.rollbar.configure({
        enabled: COMMIT_HASH !== 'dev',
        payload: { person: { id: profile.id, username: profile.username } },
      })
    }

    let docs
    if (!profile?.currentContext?.address) docs = []
    log.debug('New profile:', { profile })
    store.dispatch({ type: 'SET_PROFILE', profile, loadingProfile: false, docs })

    const cluster = profile.clusters.find(c => profile?.currentContext?.address === c.address)
    if (cluster && cluster.shared === false && cluster.verified && cluster.agentKey) {
      await fetchAgentHealth(cluster.agentKey)
    }
  } catch (err) {
    toast({ type: 'error', msg: 'Error fetching your profile', subMsg: err.message, err })
    console.error('Profile error:', { errMsg: err.message, stack: err.stack })
    return signOutHandler()
  }

  const inviteCode = Cookies.get('KSINVITECODE')
  if (profile.username && inviteCode && inviteCode !== 'complete') {
    toast({
      type: 'success',
      msg: 'Thanks for signing up!',
      subMsg:
        "We're provisioning your resources now - this may take a moment. The page will reload when it's complete!",
    })

    const { status, json } = await apiFetch(`/invite/apply-code/${inviteCode}`, { method: 'PUT' })
    if (status === 200) {
      Cookies.set('KSINVITECODE', 'complete', { expires: 1, sameSite: 'strict' })
      setTimeout(() => {
        log.debug('KSINVITECODE: Redirecting to WWW_TARGET', { redirect: WWW_TARGET })
        window.location = WWW_TARGET
      }, 2500)
    } else {
      if (json && json.expired) {
        Cookies.set('KSINVITECODE', 'complete', { expires: 1, sameSite: 'strict' })
      }
      toast({
        type: 'error',
        msg: 'Error applying invite code',
        subMsg:
          json && json.error
            ? json.error
            : 'Please try reloading the page - the cluster may be offline',
      })
    }
  }
  return profile
}
