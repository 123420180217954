import parallel from 'async/parallel'
import get from 'lodash/get'
import {
  CLUSTER_HEALTH_LIVE,
  CLUSTER_HEALTH_CACHED,
  CLUSTER_HEALTH_UNREACHABLE,
  CLUSTER_HEALTH_UNAUTHORIZED,
  docsToWatch,
  FORCED_FIXTURE_DOCS,
} from '../config'
import store from '../store'
import { log, apiFetch, toast, createSocket, setCurrentContext, filterKeys } from '.'

export async function fetchRepos() {
  const { status, json: repos } = await apiFetch('/repos')
  if (status !== 200) {
    return toast({ type: 'error', msg: 'Failed to fetch kubesail repos', err: status })
  }
  if (!repos || !Array.isArray(repos)) {
    console.error('/repos endpoint returned an incorrect response!')
    toast({ type: 'error', msg: 'Failed to fetch kubesail repos (incorrect response)' })
    return
  }
  store.dispatch({ type: 'REPOS_UPDATE', repos: [...store.getState().repos, ...repos] })
}

export default async function fetchAllDocs() {
  const state = store.getState()
  const context = {
    clusterAddress: state.profile?.currentContext?.address,
    namespace: state.profile?.currentContext?.namespace,
  }
  log.debug('fetchAllDocs:', context)
  store.dispatch({ type: 'FETCHING_ALL', ...context })

  // If there is a resource in the URI, like /services/foo or /jobs/, then let's make sure to load that first so the page feels snappy!
  const resourcePathPart = (window.location.pathname.split('/')[1] || '')
    .toLowerCase()
    .replace(/s$/, '')

  let docs = docsToWatch.slice()

  if (resourcePathPart && resourcePathPart !== 'dashboard') {
    docs.sort((a, b) => (a.kind.toLowerCase() === resourcePathPart ? -1 : 1))
  }
  const currentCluster = state.profile.clusters.find(
    c => c.address === state.profile?.currentContext?.address
  )
  if (!currentCluster) {
    log.debug('fetchAllDocs: no currentCluster, skipping')
    return
  }
  if (currentCluster.shared || currentCluster.role !== 'admin') {
    docs = docs.filter(doc => doc.kind !== 'Node')
  }

  for (const cluster of state.profile.clusters) {
    if (cluster.role) {
      const namespaceRequest = await fetchNamespaces(cluster.address)
      if (namespaceRequest.error === 404) {
        store.dispatch({
          type: 'SET_PROFILE',
          profile: {
            ...state.profile,
            clusters: state.profile.clusters.map(c => {
              if (cluster.address === c.address) {
                c.missingCredentials = true
              }
              return c
            }),
          },
        })
        return
      }
    }
  }

  // DISABLED FOR NOW TO AVOID OTHER CLUSTERS APPEARING OFFLINE
  // Pull out the first doc promise and resolve it, don't continue if it fails.
  // This has two goals:
  // 1. Allow credentials to be fetched and cached by the backed (next requests will be faster)
  // 2. Don't spam the cluster with a ton of requests that will also fail
  const firstDoc = docs.shift()
  log.debug('First doc:', { ...firstDoc })
  const firstRequestSuccess = await fetchList(firstDoc)
  const promisesToFulfill = []

  if (firstRequestSuccess) {
    docs.forEach(doc => promisesToFulfill.push(cb => fetchList(doc).then(r => cb(null, r))))
  } else {
    log.warn('fetchAllDocs: first request failed')
  }

  await parallel(promisesToFulfill)

  if (!currentCluster.shared) {
    establishWatch(currentCluster.agentKey, state.profile?.currentContext?.namespace)
  }
}

export async function establishWatch(agentKey, namespace) {
  const socket = await createSocket()
  if (typeof window !== 'undefined') {
    window._agentKubeWatch = { agentKey, namespace }
  }
  const watchData = { agentKey, namespace }
  log.debug('Emitting agent-kube-watch', watchData)
  socket.emit('agent-kube-watch', watchData)
}

async function fetchList(query) {
  const { error, status, json: body } = await apiFetch('/resources', { query })
  const state = store.getState()
  const cluster = state.profile.clusters.find(cluster => {
    return cluster.address === state.profile?.currentContext?.address
  })
  const pluralKind = query.kind.endsWith('s') ? query.kind + 'es' : query.kind + 's'

  if (error) {
    console.error('fetchList error:', error)
  }

  if (status === 401 || status === 403 || (body && body.code === 403)) {
    store.dispatch({
      type: 'SET_PROFILE',
      profile: {
        ...state.profile,
        clusters: state.profile.clusters.map(cluster => {
          if (cluster.address === state.profile?.currentContext?.address) {
            cluster.health = CLUSTER_HEALTH_UNAUTHORIZED
          }
          return cluster
        }),
      },
    })
    if (cluster) {
      toast({
        type: 'error',
        msg: `Permission denied from ${cluster.friendlyName}`,
        err: status,
        subMsg: error?.message || body?.error || body?.message || 'permission denied!',
      })
    }
    const sharedCluster = state.profile.clusters.find(c => c.shared)
    if (sharedCluster) {
      setCurrentContext(state.profile.username, sharedCluster.address)
      toast({
        msg: `Changing current context!`,
        subMsg: `Switched to namespace "${state.profile.username}" on "${sharedCluster.friendlyName}"`,
      })
    }
    return
  } else if (status === 444) {
    log.debug(`fetchList: ${cluster.address} response CLUSTER_HEALTH_UNREACHABLE (444)`)
    store.dispatch({
      type: 'SET_PROFILE',
      profile: {
        ...state.profile,
        clusters: state.profile.clusters.map(c => {
          if (c.address === cluster.address) c.health = CLUSTER_HEALTH_UNREACHABLE
          return c
        }),
      },
    })
    toast({
      type: 'error',
      msg: `Unable to fetch ${pluralKind} from ${cluster.friendlyName}`,
      err: status,
      subMsg: `${cluster.address} / ` + (body && body.error ? body.error : 'connection refused'),
    })
    store.dispatch({ type: 'FETCH_ERROR' })
    return
  } else if (status !== 200) {
    toast({
      type: 'error',
      msg: `Failed to fetch ${pluralKind} from ${cluster.friendlyName}`,
      err: status,
      subMsg: error?.message || body?.message || body?.error || status,
    })
    store.dispatch({ type: 'FETCH_ERROR' })
    return
  }
  if (!body || !body.items || !Array.isArray(body.items)) {
    console.error('/resources endpoint returned an incorrect response!', error)
    store.dispatch({ type: 'FETCH_ERROR' })
    return
  }
  const items = body.items.map(item =>
    Object.assign({}, { apiVersion: query.apiVersion, kind: query.kind }, item)
  )

  const oldDocs = store.getState().docs.filter(doc => doc.kind !== query.kind)
  const docs = [...FORCED_FIXTURE_DOCS.filter(d => d.kind === query.kind), ...oldDocs, ...items]
  store.dispatch({
    type: 'DOCS_UPDATE',
    docs,
    fetchKind: query.kind.toLowerCase(),
  })

  try {
    const val = JSON.stringify({
      clusterAddress: cluster.address,
      namespace: state.profile?.currentContext?.namespace,
      docs: docs.map(doc => {
        return { kind: doc.kind, metadata: { name: doc.metadata.name } }
      }),
    })
    localStorage.setItem('CACHED_DOCS', val)
  } catch (err) {
    console.error('Error caching docs', err)
  }

  return true
}

export async function fetchDomains() {
  log.debug('fetchDomains()')
  const { status, json: domains } = await apiFetch('/domains')
  if (status !== 200) {
    return toast({
      type: 'error',
      msg: 'Failed to fetch Domains',
      err: status,
      subMsg: domains && domains.error,
    })
  }
  if (!domains || !Array.isArray(domains)) {
    console.error('/domains endpoint returned an incorrect response!')
    toast({ type: 'error', msg: 'Failed to fetch Domains - incorrect response' })
    return
  }
  store.dispatch({ type: 'DOMAINS_UPDATE', domains })
}

const filterOutDoc = (kind, name) => doc => {
  if (!name && doc.kind !== kind) return true
  else if (doc.kind === kind && doc?.metadata?.name === name) return false
  else return true
}

export async function fetchNotifications() {
  log.debug('fetchNotifications: start')
  const { json: notifications } = await apiFetch('/notifications')
  if (Array.isArray(notifications)) {
    store.dispatch({ type: 'NOTIFICATIONS', notifications })
  }
}

export function handleKubeSailEvent(event /*: Object */) {
  const state = store.getState()
  if (!event.metricsUpdate) {
    log.debug(`KubeSailEvent event received!`, { event, hasProfile: !!state.profile })
  }
  if (!state.profile) return

  if (event.notification) {
    if (event.notification.type === 'delete') {
      store.dispatch({
        type: 'NOTIFICATIONS',
        notifications: state.notifications.map(notif => {
          if (notif.type === event.notification.data.type) {
            // TODO: Should match notification.data.data here
            notif.read = Date.now()
          }
          return notif
        }),
      })
    } else {
      store.dispatch({
        type: 'NOTIFICATIONS',
        notifications: [event.notification, ...(state.notifications || [])],
      })
    }
  }

  if (event.domain) {
    store.dispatch({ type: 'DOMAINS_UPDATE', domain: event.domain })
  }

  if (event.repoBuild) {
    event.repoBuild.expanded = true
    let added = false
    const repoBuilds = state.repoBuilds.map(build => {
      if (build.uuid === event.repoBuild.uuid) {
        added = true
        return { ...build, ...event.repoBuild }
      }
      return build
    })
    if (!added) repoBuilds.unshift(event.repoBuild)
    store.dispatch({ type: 'REPO_BUILDS_UPDATE', repoBuilds })
  }

  if (event.backup) {
    event.backup.expanded = true
    let added = false
    const volumeBackups = state.volumeBackups.map(volumeBackup => {
      if (volumeBackup.backupId === event.backup.backupId) {
        added = true
        return { ...volumeBackup, ...event.backup }
      }
      return volumeBackup
    })
    if (!added) volumeBackups.unshift(event.backup)
    store.dispatch({ type: 'VOLUME_BACKUPS', volumeBackups })
  }

  if (event.restore) {
    let added = false
    const volumeRestores = state.volumeRestores.map(volumeRestore => {
      if (volumeRestore.restoreId === event.restore.restoreId) {
        added = true
        return { ...volumeRestore, ...event.restore }
      }
      return volumeRestore
    })
    if (!added) volumeRestores.unshift(event.restore)
    store.dispatch({ type: 'VOLUME_RESTORES', volumeRestores })
  }

  if (event.cluster) {
    const clusters = state.profile.clusters
    if (event.cluster.added) {
      const existing = clusters.find(c => c.isNew || c.agentKey === event.cluster.agentKey)
      if (window._agentKubeWatch && typeof window._agentKubeWatch === 'object') {
        establishWatch(window._agentKubeWatch.agentKey, window._agentKubeWatch.namespace)
      }
      store.dispatch({
        type: 'SET_PROFILE',
        profile: {
          ...state.profile,
          clusters: existing
            ? clusters.map(c => {
                if (c.isNew || c.agentKey === event.cluster.agentKey) {
                  event.cluster.connected = true
                  return { urlSlug: 'new', ...event.cluster }
                } else {
                  return c
                }
              })
            : [...clusters, event.cluster],
        },
      })
    } else if (event.cluster.disconnected) {
      log.debug('Cluster disconnected!', { cluster: event.cluster })
      store.dispatch({
        type: 'SET_PROFILE',
        profile: {
          ...state.profile,
          clusters: clusters.map(cluster => {
            if (cluster.agentKey === event.cluster.agentKey) {
              cluster.connected = false
            }
            return cluster
          }),
        },
      })
    } else if (event.cluster.newConfig) {
      store.dispatch({
        type: 'SET_PROFILE',
        profile: {
          ...state.profile,
          clusters: clusters.map(cluster => {
            if (cluster.agentKey === event.cluster.agentKey) {
              cluster.newConfig = true
            }
            return cluster
          }),
        },
      })
    }
  }

  if (event.metricsData) {
    store.dispatch({ type: 'SET_METRICS_DATA', metricsData: event.metricsData })
  }

  if (event.diskStats) {
    store.dispatch({ type: 'SET_DISK_STATS', diskStats: event.diskStats })
  }

  const METRICS_DATAS_TO_KEEP = 40
  if (event.metricsUpdate) {
    for (let i = 0; i < event.metricsUpdate.length; i++) {
      const update = event.metricsUpdate[i]
      if (state.metricsData && state.metricsData.nodes[update.metadata.name]) {
        const newMetricsData = {
          ...state.metricsData,
          nodes: {
            ...state.metricsData.nodes,
            [update.metadata.name]: {
              ...state.metricsData.nodes[update.metadata.name],
              cpu: [update.usage.cpu, ...state.metricsData.nodes[update.metadata.name].cpu].slice(
                0,
                METRICS_DATAS_TO_KEEP
              ),
              memory: [
                update.usage.memory,
                ...state.metricsData.nodes[update.metadata.name].memory,
              ].slice(0, METRICS_DATAS_TO_KEEP),
            },
          },
        }
        store.dispatch({ type: 'SET_METRICS_DATA', metricsData: newMetricsData })
      } else {
        log.warn(
          'Received metrics data for node that we do not have a document for - this should not happen'
        )
      }
    }
  }
}

export function handleKubeEvent({ clusterAddress, namespace, kubeEvent, kubesailEvent }) {
  if (kubesailEvent) return handleKubeSailEvent(kubesailEvent)
  const state = store.getState()
  if (!kubeEvent) {
    log.debug('handleKubeEvent: rejecting event', {
      clusterAddress,
      namespace,
      kubeEvent,
      kubesailEvent,
    })
    return
  }

  const kind = kubeEvent.object.kind
  const name = kubeEvent.object.metadata.name
  const filterEvents = !['ResourceQuota', 'ReplicaSet'].includes(kind)

  const docIndex = state.docs.findIndex(doc => doc.kind === kind && doc?.metadata?.name === name)
  if (docIndex === -1 && kubeEvent.type !== 'DELETED') {
    if (filterEvents) {
      toast({
        type: 'info',
        msg: `${kind} created`,
        subMsg: `${name} ${get(kubeEvent, 'object.status.phase', '')}`,
      })
    }
    store.dispatch({ type: 'DOCS_UPDATE', docs: [...state.docs, kubeEvent.object] })
  } else if (kubeEvent.type === 'MODIFIED') {
    if (name === 'ingress-controller-leader-nginx') return
    if (name === 'ingress-controller-leader-public') return
    // if (filterEvents) {
    //   toast({
    //     type: 'info',
    //     msg: `${kind} updated`,
    //     subMsg: `${namespace}/${name} ${get(kubeEvent, 'object.status.phase', '')}`,
    //   })
    // }
    store.dispatch({
      type: 'DOCS_UPDATE',
      docs: [...state.docs].map((item, i) => (i === docIndex ? kubeEvent.object : item)),
    })
  } else if (kubeEvent.type === 'DELETED') {
    if (filterEvents)
      toast({
        type: 'info',
        msg: `${kind} deleted`,
        subMsg: `${name} ${get(kubeEvent, 'object.status.phase', '')}`,
      })
    store.dispatch({
      type: 'EXPANDED_DOCS',
      expandedDocs: state.expandedDocs.filter(t => !(t.name === name && t.kind === kind)),
    })
    window.setTimeout(() => {
      // Waits for close animation to finish, then actually deletes the doc from the store
      const state = store.getState()
      store.dispatch({
        type: 'DOCS_UPDATE',
        docs: state.docs.filter(filterOutDoc(kind, name)),
        pendingDocs: state.pendingDocs.map(p => {
          if (p.kind === kind && p.metadata && name === p.metadata.name) {
            return filterKeys(p)
          }
          return p
        }),
      })
    }, 300)
  }
}

export async function fetchNamespaces(clusterAddress /*: string */, fetchCached = true) {
  const state = store.getState()

  const currentContext = state?.profile?.currentContext?.address === clusterAddress
  log.debug('fetchNamespaces: start', { clusterAddress, fetchCached, currentContext })

  const { status, json } = await apiFetch(`/namespaces/${clusterAddress}`, {
    query: { cached: fetchCached },
    clusterAddress,
    namespace: 'default',
  })

  let health
  if (status === 401 || status === 403) {
    health = CLUSTER_HEALTH_UNAUTHORIZED
  } else if (status !== 200 || !Array.isArray(json)) {
    // Assume their cluster is offline
    health = CLUSTER_HEALTH_UNREACHABLE
    log.debug(`fetchNamespaces: ${clusterAddress} response CLUSTER_HEALTH_UNREACHABLE (444)`)
    // Disabled until we can come up with a better alerting system. Cluster offline is not an urgent error
    // toast({
    //   type: 'error',
    //   msg: `Unable to fetch namespaces from ${clusterAddress}`,
    //   subMsg: `${clusterAddress} might be offline?`,
    //   err: status,
    // })
    return { error: status }
  } else {
    if (fetchCached && currentContext) {
      // These results came from redis. We'll fetch again with no cache
      // to get the actual namespaces from k8s (assuming cluster is online)
      health = CLUSTER_HEALTH_CACHED
      setTimeout(() => {
        fetchNamespaces(clusterAddress, false)
      }, 500)
    } else {
      health = CLUSTER_HEALTH_LIVE
    }
  }

  store.dispatch({
    type: 'SET_PROFILE',
    profile: {
      ...state.profile,
      clusters: state.profile.clusters.map(c => {
        if (c.address === clusterAddress) {
          c.health = health
          c.namespaces = Array.isArray(json) ? json : []
        }
        return c
      }),
    },
  })

  return {}
}
