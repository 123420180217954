import { createStore } from 'redux'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'
import { docsToWatch } from './config'
import { sortDocs, getBaseUnits, log } from './util'

export const INITIAL_TEMPLATE = {
  fetching: true,
  username: '',
  name: '',
  logo: '',
  data: '',
  revision: 0,
  stars: 0,
  official: false,
  youStarred: false,
  invalidDocCount: 0,
  description: '',
  isPrivate: true,
  pendingIsPrivate: true,
  editingDescription: false,
  descriptionPreview: false,
  newDescription: '',
  editingPrivacy: false,
  tags: [],
}

export const initialState = {
  docs: [],
  pendingDocs: [],
  pendingDocsWithVariables: [],
  templateDocs: [],
  domains: [],
  repos: [],
  gateways: [],
  repoBuilds: [],
  profile: null,
  loadingProfile: true,
  metricsData: null,
  toastStack: [],
  targetDocs: [],
  expandedDocs: [],
  volumeBackups: [],
  volumeRestores: [],
  docFilter: {
    names: [],
    kinds: [],
  },
  notifications: [],
  fetchError: false,
  fetching: {}, // fetching kinds are added from docsToWatch below
  editor: {
    showing: false,
    title: '',
    stars: null,
    youStarred: false,
    tab: null,
    isTemplate: false,
    isPrivate: true,
    filemanager: null,
  },
  template: INITIAL_TEMPLATE,
  gatherImageMetadata: null,
  waitingForImageMetadataResults: false,
  templateHasUnsavedChanges: false,
}

docsToWatch.forEach(doc => {
  initialState.fetching[doc.kind.toLowerCase()] = true
})

function reducer(state = initialState, action) {
  if (!action.type) {
    throw new Error('RootReducer received undefined action type')
  }
  switch (action.type) {
    case 'SET_PROFILE': {
      if (action.profile?.clusters) {
        // action.profile?.clusters.sort(c => {
        //   if (
        //     action?.profile?.currentContext?.address &&
        //     c.address === action?.profile?.currentContext?.address
        //   ) {
        //     return -1
        //   }
        //   return 1
        // })
      }
      return {
        ...state,
        profile: action.profile,
        loadingProfile: action.loadingProfile || false,
        docs: action.docs || state.docs,
      }
    }
    case 'TOAST': {
      return { ...state, toastStack: action.toastStack }
    }
    case 'VOLUME_BACKUPS': {
      return { ...state, volumeBackups: action.volumeBackups }
    }
    case 'VOLUME_RESTORES': {
      return { ...state, volumeRestores: action.volumeRestores }
    }
    case 'FETCH_ERROR': {
      return { ...state, fetchError: true }
    }
    case 'REPOS_UPDATE': {
      return { ...state, repos: action.repos, fetching: { ...state.fetching, repo: false } }
    }
    case 'SET_GATEWAYS': {
      return { ...state, gateways: action.gateways }
    }
    case 'REPO_BUILDS_UPDATE': {
      return { ...state, repoBuilds: uniqBy([...action.repoBuilds, ...state.repoBuilds], 'uuid') }
    }
    case 'DOCS_UPDATE': {
      const templateHasUnsavedChanges =
        typeof action.templateHasUnsavedChanges === 'boolean'
          ? action.templateHasUnsavedChanges
          : state.templateHasUnsavedChanges
      return {
        ...state,
        docs: sortDocs(action.docs || state.docs),
        fetching: action.fetchKind
          ? { ...state.fetching, [action.fetchKind]: false }
          : state.fetching,
        pendingDocs: action.pendingDocs || state.pendingDocs,
        expandedDocs: action.expandedDocs || state.expandedDocs,
        templateDocs: action.templateDocs || state.templateDocs,
        targetDocs: action.targetDocs || state.targetDocs,
        templateHasUnsavedChanges,
      }
    }
    case 'PENDING_DOCS_UPDATE': {
      return {
        ...state,
        pendingDocs: action.pendingDocs,
        templateDocs: action.templateDocs || state.templateDocs,
        expandedDocs: action.expandedDocs || state.expandedDocs,
      }
    }
    case 'REMOVE_DOC': {
      const f = d => d.kind !== action.kind && d?.metadata?.name !== action.name
      return { ...state, docs: state.docs.filter(f) }
    }
    case 'REMOVE_PENDING_DOC': {
      const f = d => d.kind !== action.kind && d?.metadata?.name !== action.name
      return {
        ...state,
        pendingDocs: state.pendingDocs.filter(f),
        templateDocs: action.template ? state.templateDocs : state.templateDocs.filter(f),
        expandedDocs: state.expandedDocs.filter(
          d => d.kind !== action.kind && d?.name !== action.name
        ),
      }
    }
    case 'PENDING_DOCS_WITH_VARIABLES': {
      return {
        ...state,
        pendingDocsWithVariables: action.pendingDocsWithVariables,
      }
    }
    // TODO: This should probably be aware of which node these disk stats apply to
    case 'SET_DISK_STATS': {
      if (!action.diskStats) {
        return { ...state, diskStats: null }
      }

      if (action.diskStats.error) {
        return { ...state, diskStats: { error: action.diskStats.error } }
      }

      const lvs = action.diskStats.Lvs
        ? JSON.parse(action.diskStats.Lvs)?.report?.[0]?.lv || []
        : []
      const pvs = action.diskStats.Pvs
        ? JSON.parse(action.diskStats.Pvs)?.report?.[0]?.pv || []
        : []
      const lv = lvs.find(l => l.lv_name === 'k3s')

      let lsblk
      if (action?.diskStats?.Lsblk) {
        lsblk = JSON.parse(action?.diskStats?.Lsblk)
      }

      const disks = {}
      const models = action?.diskStats?.Models || []

      models.forEach(disk => {
        const [mapping, serial] = disk.split(' ')
        disks[mapping] = {
          serial,
          slug: serial
            .trim()
            .toLowerCase()
            .replace(/[^a-z0-9-]/g, '-'),
          blockDevice: lsblk ? lsblk.blockdevices.find(b => b.name === mapping) : null,
        }
      })

      const partitions = action?.diskStats?.Partitions || []
      partitions.forEach(disk => {
        const splits = disk.split('\n')
        const mapping = splits.shift().split(' ')[0]
        if (!mapping.startsWith('sd') && !mapping.startsWith('nvme')) {
          return
        }
        const overview = splits.shift()
        const [, size] = overview.split(':')
        if (!disks[mapping]) disks[mapping] = {}
        disks[mapping] = {
          ...disks[mapping],
          size,
          overview,
          partitions: splits,
          path: overview.split(':')[0],
        }
      })

      function parseDF(parts) {
        const used = parseInt(parts[2], 10)
        const free = parseInt(parts[3], 10)
        const total = used + free
        const mount = parts[5]
        return { used, free, total, mount }
      }

      let rootUsage = {}
      if (action?.diskStats?.RootUsage) {
        const rootUsageParts = action?.diskStats?.RootUsage[1].replace(/  +/g, ' ').split(' ')
        rootUsage = parseDF(rootUsageParts)
      }
      let k3sUsage = {}
      if (action?.diskStats?.K3sUsage) {
        const k3sUsageParts = action?.diskStats?.K3sUsage[1].replace(/  +/g, ' ').split(' ')
        k3sUsage = parseDF(k3sUsageParts)
      }

      const capacity = getBaseUnits(action.diskStats.capacity)
      let summary
      const volObjects = (action?.diskStats?.K3sStorageUsage || [])
        .map(vol => {
          const [usage, path] = vol.replace('/var/lib/rancher/k3s/storage/', '').split(' ')
          const filename = (path || '').split('/').pop()
          const [volumeName, namespace, pvcName] = filename.split('_')
          const data = { usage: parseInt(usage, 10), pvcName, namespace, volumeName, path }
          if (!path) {
            summary = data
            return null
          }
          if (!namespace) {
            data.volumeName = path
            return data
          } else if (namespace === state.profile.currentContext.namespace) {
            return data
          }
        })
        .filter(Boolean)

      const mountPoints = JSON.parse(action?.diskStats?.MountPoints || '{}')?.filesystems
      const sortedVols = sortBy(volObjects, 'usage').reverse()
      const diskStats = {
        summary,
        capacity,
        k3sUsage,
        sortedVols,
        disks,
        lv,
        lvs,
        pvs,
        mountPoints,
        rootUsage,
        lsblk,
      }
      log.debug('Processed disk-stats object:', { diskStats })
      return { ...state, diskStats }
    }
    case 'SET_PENDING_DOC': {
      let matchedExisting = false
      const pendingDocs = state.pendingDocs.map(doc => {
        const kind = get(doc, 'kind') === get(action.doc, 'kind')
        const name = get(doc, 'metadata.name') === get(action.doc, 'metadata.name')
        const kubesailEditorUid =
          get(doc, 'metadata.annotations.kubesailEditorUid') &&
          get(doc, 'metadata.annotations.kubesailEditorUid') ===
            get(action.doc, 'metadata.annotations.kubesailEditorUid')

        if ((name && kind) || kubesailEditorUid) {
          matchedExisting = true
          return action.doc
        }
        return doc
      })
      if (!matchedExisting) pendingDocs.push(action.doc)
      return { ...state, pendingDocs }
    }
    case 'DOMAINS_UPDATE': {
      let domains = action.domains || state.domains
      if (action.domain) {
        let foundDomain = false
        domains = domains.map(domain => {
          if (domain.name === action.domain.name) {
            foundDomain = true
            return action.domain
          } else {
            return domain
          }
        })
        if (!foundDomain) domains.push(action.domain)
      }
      return {
        ...state,
        domains,
        fetching: { ...state.fetching, domain: false },
      }
    }
    case 'SET_METRICS_DATA': {
      return { ...state, metricsData: action.metricsData }
    }
    case 'STOP_FETCHING': {
      return { ...state, fetching: {} }
    }
    case 'FETCHING_ALL': {
      const fetching = cloneDeep(state.fetching)

      docsToWatch.forEach(doc => {
        fetching[doc.kind.toLowerCase()] = true
      })

      return {
        ...state,
        fetching,
        fetchError: false,
        docs: [],
        editor: { ...state.editor },
      }
    }
    case 'TARGET_DOCS': {
      return { ...state, targetDocs: action.targetDocs }
    }
    // EXPANDED_DOCS is used by UI
    case 'EXPANDED_DOCS': {
      return { ...state, expandedDocs: action.expandedDocs }
    }
    case 'FILTERED_DOCS': {
      return { ...state, docFilter: action.docFilter }
    }
    case 'NOTIFICATIONS': {
      const notifications = [...new Map(action.notifications.map(item => [item.id, item])).values()]
      return { ...state, notifications }
    }
    case 'CLEAR_EDITOR': {
      return {
        ...state,
        editor: { ...state.editor, yaml: '' },
      }
    }
    case 'UPDATE_EDITOR': {
      let showing = true
      if (action.showing !== undefined) showing = action.showing
      else if (action.noOpen) showing = state.editor.showing
      else if (action.yaml) showing = true
      else showing = !!(state.targetDocs && state.targetDocs.length > 0)
      const targetDocs = [...state.targetDocs]
      if (
        action.tab &&
        !targetDocs.find(({ kind, name }) => action.tab.kind === kind && action.tab.name === name)
      ) {
        targetDocs.push(action.tab)
      }
      const yaml = action.yaml || state.editor.yaml
      const newState = {
        ...state,
        targetDocs,
        expandedDocs: action.expandedDocs || state.expandedDocs,
        editor: {
          tab: action.tab || action.tab === null ? action.tab : state.editor.tab,
          showing,
          title: action.title || state.editor.title,
          yaml,
          docType: action.docType || 'YAML',
          filemanager: action.filemanager || null,
          revision: action.revision || state.editor.revision,
          isTemplate:
            typeof action.isTemplate === 'boolean' ? action.isTemplate : state.editor.isTemplate,
          isPrivate:
            typeof action.isPrivate === 'boolean' ? action.isPrivate : state.editor.isPrivate,
          templateUsername: action.templateUsername || state.editor.templateUsername,
          name: action.name || state.editor.name,
          scrollToDocument:
            typeof action.scrollToDocument === 'number'
              ? action.scrollToDocument
              : state.editor.scrollToDocument,
        },
      }
      return newState
    }
    case 'RESET_TEMPLATE': {
      return { ...state, template: INITIAL_TEMPLATE }
    }
    case 'TEMPLATE_UPDATE': {
      const templateDocs = action.templateDocs || state.templateDocs
      const template = cloneDeep(action)
      delete template.type
      if (!template.tags) template.tags = []
      return {
        ...state,
        template: {
          ...state.template,
          ...template,
          editingDescription:
            typeof action.editingDescription === 'boolean'
              ? action.editingDescription
              : state.editingDescription,
        },
        templateHasUnsavedChanges:
          typeof action.templateHasUnsavedChanges === 'boolean'
            ? action.templateHasUnsavedChanges
            : state.templateHasUnsavedChanges,
        templateDocs,
      }
    }
    case 'UPDATE_ONE_DOC': {
      const match = d => {
        if (d.kind === action.doc.kind && d?.metadata?.name === action.doc.metadata.name) {
          return action.doc
        }
        return d
      }
      return {
        ...state,
        templateDocs: action.includeTemplate ? state.templateDocs.map(match) : state.templateDocs,
        pendingDocs: state.pendingDocs.map(match),
        templateHasUnsavedChanges: true,
      }
    }
    case 'SET_IMAGE_METADATA': {
      const newState = { ...state }
      if (typeof action.waitingForImageMetadataResults === 'boolean') {
        newState.waitingForImageMetadataResults = action.waitingForImageMetadataResults
      }
      if (typeof action.gatherImageMetadata === 'object') {
        newState.gatherImageMetadata = action.gatherImageMetadata
      }
      return newState
    }
    case 'CLEAR_IMAGE_METADATA': {
      return {
        ...state,
        gatherImageMetadata: null,
      }
    }
    default:
      return state
  }
}

const store = createStore(reducer)

export default store
