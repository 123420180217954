// @flow

const overrideLocalhost = str => {
  if (process.env.OVERRIDE_LOCALHOST) {
    return str.replace(/localhost/g, process.env.OVERRIDE_LOCALHOST)
  }
  return str
}

const trimTrailingSlash = str => {
  return str.replace(/\/$/, '')
}

export const API_TARGET = trimTrailingSlash(
  overrideLocalhost(process.env.NEXT_PUBLIC_API_TARGET || 'https://api.kubesail.com')
)

export const INTERNAL_API_TARGET = process.env.NEXT_PUBLIC_INTERNAL_API_TARGET || API_TARGET

export const WWW_TARGET = trimTrailingSlash(
  overrideLocalhost(process.env.NEXT_PUBLIC_WWW_TARGET || 'https://kubesail.com')
)
export const WSS_TARGET = trimTrailingSlash(
  overrideLocalhost(process.env.NEXT_PUBLIC_WSS_TARGET || 'wss://api.kubesail.com')
)
export const BYOC_TARGET = trimTrailingSlash(
  overrideLocalhost(process.env.NEXT_PUBLIC_BYOC_TARGET || 'https://byoc.kubesail.com')
)
export const NODE_ENV = process.env.NODE_ENV || 'development'

// Must match service configuration
export const COOKIE_DOMAIN = overrideLocalhost(process.env.NEXT_PUBLIC_COOKIE_DOMAIN || 'localhost')

// Local Storage keys for storing current context
export const KUBESAIL_NAMESPACE_KEY =
  process.env.NEXT_PUBLIC_KUBESAIL_NAMESPACE_KEY || 'kubesailNamespace'
export const KUBESAIL_CLUSTER_ADDRESS_KEY =
  process.env.NEXT_PUBLIC_KUBESAIL_CLUSTER_ADDRESS_KEY || 'kubesailClusterAddress'
export const KUBESAIL_LAST_USED_CONTEXT_KEY = 'kubesailLastUsedContext'

export const ROLLBAR_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN || 'ba0f0ecde6d64a47874d2006a049d3b2'

export const STRIPE_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY || 'pk_test_7AkRzftaxjOZWEiGFNy7sPYP'
export const STRIPE_OAUTH_CLIENT_ID =
  process.env.NEXT_PUBLIC_STRIPE_OAUTH_CLIENT_ID || 'ca_IzBT1oLJMtWrTweD5ckO60o5imtrp8VF'
export const STRIPE_TEST_OAUTH_CLIENT_ID =
  process.env.NEXT_PUBLIC_STRIPE_TEST_OAUTH_CLIENT_ID || 'ca_IzBTx9Y6NMt1TSB4apkZekg3IECHzfNS'

export const COMMIT_HASH = process.env.NEXT_PUBLIC_COMMIT_HASH || 'dev'

export const GITHUB_DEPLOYBOT_NAME =
  process.env.NEXT_PUBLIC_GITHUB_DEPLOYBOT_NAME || 'kubesail-deploybot-dev'

export const LOG_LEVEL =
  process.env.NEXT_PUBLIC_LOG_LEVEL || COMMIT_HASH === 'dev' ? 'debug' : 'info'

// Callback port that the kubesail register-cli package listens on
export const LISTEN_PORT_COOKIE_NAME = 'listenPort'

export const SITE_TITLE_PREFIX = NODE_ENV === 'development' ? 'DevSail |' : 'KubeSail |'

export const KUBESAIL_TOOLS_IMAGE = 'kubesail/k8s-tools:v6'
export const CLUSTER_HEALTH_LIVE = 'live'
export const CLUSTER_HEALTH_CACHED = 'cached'
export const CLUSTER_HEALTH_UNREACHABLE = 'unreachable'
export const CLUSTER_HEALTH_UNAUTHORIZED = 'unauthorized'
export const ADMIN_USERS = ['PastuDan', 'erulabs']

export const templatesToShowcase = [
  `erulabs/photostructure`,
  `erulabs/jellyfin`,
  `PastuDan/tandoor-recipes`,
  `erulabs/nextcloud`,
  `erulabs/home-assistant`,
  `erulabs/code-server`,
  `erulabs/element`,
  `erulabs/nocodb`,
  `erulabs/simpletorrent`,
]

export const KUBESAIL_SYNC_VERSION = '22'

export const REDIRECT_KEY = 'login_redirect'

if (typeof window !== 'undefined') {
  window.COMMIT_HASH = COMMIT_HASH
}

export const importanceOfDocuments = [
  'Deployment',
  'Namespace',
  'StatefulSet',
  'DaemonSet',
  'Pod',
  'Service',
  'Ingress',
  'PersistentVolumeClaim',
  'ConfigMap',
  'Job',
  'ReplicaSet',
  'ServiceAccount',
]

export const docsToWatch = [
  { apiVersion: 'apps/v1', kind: 'Deployment' },
  { apiVersion: 'networking.k8s.io/v1', kind: 'Ingress' },
  { apiVersion: 'v1', kind: 'Pod' },
  { apiVersion: 'v1', kind: 'Secret' },
  { apiVersion: 'v1', kind: 'Service' },
  { apiVersion: 'v1', kind: 'PersistentVolumeClaim' },
  { apiVersion: 'v1', kind: 'Node' },
  { apiVersion: 'v1', kind: 'ConfigMap' },
  { apiVersion: 'apps/v1', kind: 'ReplicaSet' },
  { apiVersion: 'batch/v1', kind: 'Job' },
  { apiVersion: 'apps/v1', kind: 'StatefulSet' },
  { apiVersion: 'batch/v1', kind: 'CronJob' },
  // { apiVersion: 'v1', kind: 'ReplicationController' },
  // { apiVersion: 'v1', kind: 'ResourceQuota' },
]

export const FORCED_FIXTURE_DOCS =
  COMMIT_HASH !== 'dev'
    ? []
    : [
        // {
        //   apiVersion: 'apps/v1',
        //   kind: 'Deployment',
        //   metadata: {
        //     name: 'container-creating',
        //     labels: { app: 'container-creating' },
        //     annotations: {
        //       kubesailEditorUid: 'bd596b90-9561-4378-a476-0885223158fe',
        //       'kubesail.com/template': 'erulabs/container-creating',
        //     },
        //   },
        //   spec: {
        //     strategy: { type: 'Recreate' },
        //     replicas: 1,
        //     selector: { matchLabels: { app: 'container-creating' } },
        //     template: {
        //       metadata: { labels: { app: 'container-creating' } },
        //       spec: {
        //         containers: [
        //           {
        //             name: 'container-creating',
        //             image: 'linuxserver/babybuddy:1.10.2',
        //             imagePullPolicy: 'IfNotPresent',
        //           },
        //         ],
        //       },
        //     },
        //   },
        // },
        // {
        //   apiVersion: 'v1',
        //   kind: 'Pod',
        //   metadata: {
        //     labels: { app: 'container-creating' },
        //     name: 'container-creating-6d6597dcd9-95szd',
        //     namespace: 'default',
        //     ownerReferences: [
        //       {
        //         apiVersion: 'apps/v1',
        //         blockOwnerDeletion: true,
        //         controller: true,
        //         kind: 'ReplicaSet',
        //         name: 'container-creating-6d6597dcd9',
        //         uid: '1a714bed-9847-4315-98c6-8b0930736031',
        //       },
        //     ],
        //     resourceVersion: '99913',
        //     uid: '8d33150f-3430-43ca-97ed-2810adee09b3',
        //   },
        //   spec: {
        //     containers: [
        //       {
        //         image: 'linuxserver/babybuddy:1.10.2',
        //         imagePullPolicy: 'IfNotPresent',
        //         name: 'container-creating',
        //         resources: {},
        //         terminationMessagePath: '/dev/termination-log',
        //         terminationMessagePolicy: 'File',
        //       },
        //     ],
        //     dnsPolicy: 'ClusterFirst',
        //     enableServiceLinks: true,
        //     nodeName: 'pasadena-ryzen',
        //     preemptionPolicy: 'PreemptLowerPriority',
        //     priority: 0,
        //     restartPolicy: 'Always',
        //     schedulerName: 'default-scheduler',
        //     securityContext: {},
        //     serviceAccount: 'default',
        //     serviceAccountName: 'default',
        //     terminationGracePeriodSeconds: 30,
        //   },
        //   status: {
        //     conditions: [
        //       {
        //         lastProbeTime: null,
        //         lastTransitionTime: '2022-06-29T19:02:47Z',
        //         status: 'True',
        //         type: 'Initialized',
        //       },
        //       {
        //         lastProbeTime: null,
        //         lastTransitionTime: '2022-06-29T19:02:47Z',
        //         message: 'containers with unready status: [babybuddy]',
        //         reason: 'ContainersNotReady',
        //         status: 'False',
        //         type: 'Ready',
        //       },
        //       {
        //         lastProbeTime: null,
        //         lastTransitionTime: '2022-06-29T19:02:47Z',
        //         message: 'containers with unready status: [babybuddy]',
        //         reason: 'ContainersNotReady',
        //         status: 'False',
        //         type: 'ContainersReady',
        //       },
        //       {
        //         lastProbeTime: null,
        //         lastTransitionTime: '2022-06-29T19:02:47Z',
        //         status: 'True',
        //         type: 'PodScheduled',
        //       },
        //     ],
        //     containerStatuses: [
        //       {
        //         image: 'linuxserver/babybuddy:1.10.2',
        //         imageID: '',
        //         lastState: {},
        //         name: 'babybuddy',
        //         ready: false,
        //         restartCount: 0,
        //         started: false,
        //         state: {
        //           waiting: {
        //             reason: 'ContainerCreating',
        //           },
        //         },
        //       },
        //     ],
        //     hostIP: '172.20.145.111',
        //     phase: 'Pending',
        //     qosClass: 'BestEffort',
        //     startTime: '2022-06-29T19:02:47Z',
        //   },
        // },
      ]
